import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import BaseSection from './BaseSection';
import { withComponents } from '../components/Context';
import Button from '../components/Button';
import { withEdit } from '../Context';

class RawItem extends Component {
  handleChange = (e) => {
    const { name, value } = e.target;
    const { namePrefix, onChange } = this.props;
    if (namePrefix) {
      onChange(`${namePrefix}.${name}`, value);
    } else {
      onChange(name, value);
    }
  };

  render() {
    const {
      template,
      imageFormat,
      title,
      image,
      description,
      components,
      buttonText,
      url,
      isEditing,
    } = this.props;
    const { RichText, Image } = components;
    return (
      <div className={cx(`item--${template}`, template === 'card' ? 'box' : '')}>
        <div>
          {imageFormat && imageFormat !== 'none' && (
            <div className="item__image">
              <Image src={image} maxWidth={1000} format={imageFormat} isGallery />
            </div>
          )}
          <div style={{ fontWeight: 'bold', marginBottom: 4 }}>
            <RichText html={title} name="title" placeholder="Add title" />
          </div>
          <RichText html={description} name="description" placeholder="Add description" />
        </div>

        {template === 'link' && (
          <div style={{ padding: '8px 0' }}>
            <Button
              as={isEditing ? undefined : 'a'}
              target="_blank"
              rel="noopener noreferrer"
              href={isEditing ? undefined : url}
              className="button"
            >
              <RichText html={buttonText} name="buttonText" placeholder="Button text" />
            </Button>
          </div>
        )}
        {isEditing && template === 'link' && (
          <div className="field" style={{ marginTop: 4 }}>
            <input
              className="input"
              name="url"
              placeholder="url"
              value={url || ''}
              onChange={this.handleChange}
              style={{ fontSize: '1em' }}
            />
          </div>
        )}
      </div>
    );
  }
}

RawItem.defaultProps = {
  buttonText: '',
  description: '',
  image: undefined,
  imageFormat: 'none',
  isEditing: false,
  template: 'text',
  title: '',
  url: undefined,
  namePrefix: undefined,
};

RawItem.propTypes = {
  buttonText: PropTypes.string,
  components: PropTypes.object.isRequired,
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  imageFormat: PropTypes.oneOf(['none', '16/9', '4/3', 'square']),
  isEditing: PropTypes.bool,
  namePrefix: PropTypes.string,
  template: PropTypes.oneOf(['text', 'card', 'link']),
  title: PropTypes.string,
  url: PropTypes.string,
};

const Item = withComponents(withEdit(RawItem));

class TextSection extends PureComponent {
  renderItem = (item) => {
    const { template, imageFormat } = this.props;
    return <Item {...item} template={template} imageFormat={imageFormat} />;
  };

  render() {
    const { itemList, gridSize, components, template, isEditing, ...props } = this.props;
    const { Grid } = components;
    return (
      <BaseSection {...props} block={this.props} isEditing={isEditing}>
        <Grid
          items={itemList.items}
          name="itemList.items"
          gridSize={gridSize}
          tabletGridSize={Math.round(gridSize / 2)}
          template={template}
          render={this.renderItem}
        />
      </BaseSection>
    );
  }
}

TextSection.defaultProps = {
  gridSize: 1,
  imageFormat: 'none',
  isEditing: false,
  itemList: { items: [] },
  template: 'text',
};

TextSection.propTypes = {
  components: PropTypes.object.isRequired,
  gridSize: PropTypes.number,
  imageFormat: PropTypes.oneOf(['none', '16/9', '4/3', 'square']),
  isEditing: PropTypes.bool,
  itemList: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string })),
  }),
  template: PropTypes.oneOf(['text', 'card', 'link']),
};

export default withEdit(withComponents(TextSection));
